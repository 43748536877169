import React from 'react';
import { Box } from '~/components/Primitives/Box';
import { XIcon } from '~/components/Primitives/Icon';
import { IconButton } from '~/components/Primitives/IconButton';
import { Text } from '~/components/Primitives/Text';

export const Toast = ({ appearance, children, onDismiss }) => {
  const isSuccess = appearance === 'success';
  const isError = appearance === 'error';

  return (
    <Box
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: '$background',
        border: '1px solid $colors$gray200',
        boxShadow: '0 0 8px $colors$gray200',
        minHeight: 56,
        borderRadius: 4,
        pl: 20,
        pr: 12,
        py: 12,
        mb: 8,
      }}
    >
      <Text css={{ mr: 24 }} size='body-12'>
        {children}
      </Text>
      <IconButton onClick={onDismiss} size='tiny'>
        <XIcon size='tiny' />
      </IconButton>
    </Box>
  );
};
