import { withPasswordProtect } from '@storyofams/next-password-protect';
import { NextComponentType } from 'next';
import { ThemeProvider } from 'next-themes';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import React, { ReactNode, useEffect } from 'react';
import { ToastProvider } from 'react-toast-notifications';
import { analytics } from '~/analytics/analytics';
import { Toast } from '~/components/Primitives/Toast';

import { darkTheme, globalStyles } from '~/stitches.config';

type MyAppProps = AppProps & {
  Component: NextComponentType & { layout?: any };
};

const MyApp = ({ Component, pageProps }: MyAppProps) => {
  const router = useRouter();
  const Layout = Component.layout ?? ((props: { children: ReactNode }) => <>{props.children}</>);

  globalStyles();

  useEffect(() => {
    analytics.page();

    const handleRouteChange = (path) => {
      analytics.page({ url: window.location.href, path });
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  return (
    <ThemeProvider
      disableTransitionOnChange={false}
      attribute='class'
      value={{ dark: darkTheme.toString(), light: 'light-theme' }}
      defaultTheme='system'
    >
      <ToastProvider autoDismiss autoDismissTimeout={6000} components={{ Toast }} placement='bottom-right'>
        <Layout {...pageProps}>
          <Component {...pageProps} />
        </Layout>
      </ToastProvider>
    </ThemeProvider>
  );
};

export default withPasswordProtect(MyApp);
