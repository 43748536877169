import Analytics from 'analytics';
import segmentPlugin from '@analytics/segment';

export const analytics = Analytics({
  app: 'thrive-calculator',
  plugins: [
    segmentPlugin({
      writeKey: 'S4kawQcExJeHjgMSIjcQ62mgfVPLCFta',
    }),
  ],
});
